for (var i=0;i<parent.frames.length;i++) {  
	if ((parent.frames[i].name == "ControlPageUP") || (parent.frames[i].name == "MainSettings") || (parent.frames[i].name == "criteria"))
		parent.location.href = self.location.href;
}

function ReadCookie(cookieName) {
	var theCookie = "" + document.cookie;
	var ind = theCookie.indexOf(cookieName);
	if (ind == -1 || cookieName == "") return "";
	var ind1 = theCookie.indexOf(';', ind);
	if (ind1 == -1) ind1 = theCookie.length;
	return unescape(theCookie.substring(ind + cookieName.length + 1, ind1));
}

function SetCookie(c_name, value, expiredays) {
	var exdate = new Date();
	exdate.setDate(exdate.getDate() + expiredays);
	document.cookie = c_name + "=" + escape(value) +
	((expiredays == null) ? "" : ";expires=" + exdate.toUTCString());
}

function ChangeLanguage(language) {
	document.frmLogin.action = 'default.aspx?sLanguageCode=' + language;
	document.getElementById('submitButton').disabled = true;
	SetCookie('sLanguageCode', language, 365);
	document.frmLogin.submit(); 
}

function ChangeGelLanguage(language) {
    SetCookie('sLanguageCode', language, 365);
	window.location.href = 'default.aspx?sLanguageCode=' + language;

}

function ShowErrorMessage(message) {
	var errorDiv = document.getElementById('error');
	if (errorDiv.innerHTML != '' && message != '')
		errorDiv.innerHTML += '<BR>';

	errorDiv.innerHTML += message;
	var infoPanelStyle = document.getElementById('infoPanel').style;
	infoPanelStyle.display = '';
	infoPanelStyle.visibility = 'visible';
}
